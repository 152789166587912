import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";

export default ({ data }) => {
	return (
		<Layout>
			<main>
				<Helmet>
					<title>Email open tracking</title>
				</Helmet>

				<header className="aboveFold">
					<div className="container">
						<h1>Email open tracking</h1>
					</div>
				</header>

				<section className="container container--text">
					<p>
						Sidemail features email open tracking. If enabled, it's available
						for emails sent by:
					</p>

					<ul className="list u-mb4">
						<li>
							<Link to="/targeted-email-messages">Messenger</Link>
						</li>
						<li>
							<Link to="/automated-email-sequences">Automation</Link>
						</li>
						<li>
							<Link to="/email-sending-api">Email API</Link>
						</li>
					</ul>

					<Img
						fluid={data.keyMetricsImage.childImageSharp.fluid}
						className="u-mb4"
					/>
				</section>
			</main>
		</Layout>
	);
};

export const query = graphql`
	query {
		keyMetricsImage: file(
			relativePath: { eq: "sidemail-public--api-metrics.png" }
		) {
			...twoColumnsImage
		}
	}
`;
